<template>
  <div class="status">
    <el-tag v-if="name === 'PAID'" type="success" size="small" effect="dark"
      >Payée</el-tag
    >
    <el-tag v-if="name === 'VALID'" type="success" size="small" effect="dark"
      >Terminé</el-tag
    >
    <el-tag v-if="name === 'OPEND'" type="" size="small" effect="dark"
      >Ouvert</el-tag
    >
    <el-tag v-if="name === 'CLOSED'" type="success" size="small" effect="dark"
      >Terminé</el-tag
    >
    <el-tag v-if="name === 'CANCELED'" type="danger" size="small" effect="dark"
      >Annulé</el-tag
    >
    <el-tag v-if="name === 'ACCEPTED'" type="success" size="small" effect="dark"
      >Accepté</el-tag
    >
    <el-tag
      v-if="name === 'ACCEPTED_CLOSE'"
      type="success"
      size="small"
      effect="dark"
      >Accepté et Transf.</el-tag
    >

    <el-tag v-if="name === true" type="success" size="small" effect="dark"
      >Activé</el-tag
    >

    <el-tag
      v-if="name === 'IN_PROGRESS'"
      type="warning"
      size="small"
      effect="dark"
      >En traitement</el-tag
    >
    <el-tag v-if="name === 'REFUSED'" type="danger" size="small" effect="dark"
      >Refusé</el-tag
    >
    <el-tag v-if="name === false" type="danger" size="small" effect="dark"
      >Désactivé</el-tag
    >
    <el-tag v-if="name === 'DELIVRED'" type="success" size="small" effect="dark"
      >Delivré</el-tag
    >
    <el-tag v-if="name === 'DRAFT'" type="info" size="small" effect="dark"
      >Au brouillon</el-tag
    >
    <div v-if="name === 'IN'" class="success">
      <span class="font-semibold"><i class="el-icon-bottom"></i> ENTRÉE </span>
    </div>

    <div v-if="name === 'OUT'" class="danger">
      <span class="font-semibold"> <i class="el-icon-top"></i> SORTIE</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.status {
  line-height: 30px;
  font-weight: 500;
}

.success,
.warning,
.danger,
.opend,
.draft,
.delivre {
  display: flex;
  justify-content: start;
  align-items: center;
  svg {
    width: 14px;
    margin-right: 4px;
  }
}
.success {
  color: rgb(34 197 94);
}
.warning {
  color: rgb(234 179 8);
}
.danger {
  color: rgb(239 68 68);
}
.opend {
  color: rgb(139 92 246);
}
.draft {
  color: rgb(217 70 239);
}
.delivre {
  color: rgb(16 185 129);
}
</style>
