import request from "@/utils/request";

// factures
export function exportInvoicePDF(invoiceId) {
  return request({
    url: `/export/pdf/invoice/${invoiceId}`,
    method: "get",
    responseType: "blob",
  });
}

// notecredit
export function exportCREDITNOTEPDF(invoiceId) {
  return request({
    url: `/export/pdf/creditnote/${invoiceId}`,
    method: "get",
    responseType: "blob",
  });
}

// Ticket

export function exportTicketPDF(ticketID) {
  return request({
    url: `/export/pdf/ticket/${ticketID}`,
    method: "get",
    responseType: "blob",
  });
}

export function exportPOSRapportPDF(number) {
  return request({
    url: `/export/pdf/pos-rapport/${number}`,
    method: "get",
    responseType: "blob",
  });
}

export function exportInvoiceListPDF(query) {
  return request({
    url: `/export/invoice-list`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportQuotePDF(quoteId) {
  return request({
    url: `/export/pdf/quote/${quoteId}`,
    method: "get",
    responseType: "blob",
  });
}
export function exportQuoteListPDF(query) {
  return request({
    url: `/export/quote-list`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

// export des bons de commandes
export function exportOrderPDF(orderId) {
  return request({
    url: `/export/pdf/order/${orderId}`,
    method: "get",
    responseType: "blob",
  });
}
export function exportOrderListPDF(query) {
  return request({
    url: `/export/order-list`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

// bon de livraison
export function exportDeliveryPDF(deliveryID) {
  return request({
    url: `/export/pdf/delivery/${deliveryID}`,
    method: "get",
    responseType: "blob",
  });
}
export function exportDeliveryListPDF(query) {
  return request({
    url: `/export/delivery-list`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

// Order d'achat

export function exportOrderAchatPDF(id) {
  return request({
    url: `export/pdf/purchase-order/${id}`,
    method: "get",
    responseType: "blob",
  });
}

export function exportRecuPDF(paymentId) {
  return request({
    url: `/export/pdf/payment-invoice/${paymentId}`,
    method: "get",
    responseType: "blob",
  });
}

// customer
export function exportCustomersList(query) {
  return request({
    url: `/export/customer-list`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportCustomersAmountList(query) {
  return request({
    url: `/export/customer/statistique`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

// rapporting des ventes

export function exportRapportSale(query) {
  return request({
    url: "/export/sales",
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportRapportCustomerSale(query) {
  return request({
    url: "/export/sales/customers",
    method: "get",
    params: query,
    responseType: "blob",
  });
}

// export transfert de stock

export function exportTransferStock(code) {
  return request({
    url: `/export/transfer-stock/${code}`,
    method: "get",
    responseType: "blob",
  });
}

export function exportInventoryItemPDF(code, query) {
  return request({
    url: `/export/pdf/inventory-item/${code}`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}
// export excel

export function exportExcelCustomers(query) {
  return request({
    url: `/export/excel/customer`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportInventoryItemExcel(code, query) {
  return request({
    url: `/export/excel/inventory-item/${code}`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportExcelAllCustomers(query) {
  return request({
    url: `/export/excel/customer/all`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportExcelAllDocument(query) {
  return request({
    url: `/export/excel/documents`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportExcelPaymentSale(query) {
  return request({
    url: `/export/excel/payment-sales`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportExcelRapportSale(query) {
  return request({
    url: `/export/excel/rapport-sales`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}


export function exportExcelRapportSaleCustomer(query) {
  return request({
    url: `/export/excel/rapport-sales/customers`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function exportExcelRapportRevenue(year) {
  return request({
    url: `/export/excel/revenues/${year}`,
    method: "get",
    responseType: "blob",
  });
}



export function exportExcelRapportBillByArticle(query) {
  return request({
    url: `/export/excel/bill/article`,
    method: "get",
    params:query,
    responseType: "blob",
  });
}

export function exportExcelRapportBillBySupplier(query) {
  return request({
    url: `/export/excel/bill/supplier`,
    method: "get",
    params:query,
    responseType: "blob",
  });
}