<template>
  <el-dialog
    :title="`APERCU FACTURE`"
    :visible="visible"
    @close="handleClose"
    width="50%"
    top="1vh"
  >
    <iframe :src="url" width="100%" height="600px">
      Oops! an error has occurred.
    </iframe>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Fermer</el-button>
      <el-button type="primary" icon="el-icon-print" @click="handlePrint"
        >Imprimer</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "PDFView",
  props: {
    url: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      console.log("hello");
      this.$emit("close", false);
    },
    handlePrint() {
      let iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = this.url;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
