<template>
  <div>
    <Loading v-if="loadingFull" />
    <div class="confirm">
      <el-dialog :visible.sync="dialogVisible" width="30%" @closed="closeModal">
        <div class="flex ch">
          <div class="mb-3 item-ch" @click="handleTransform('INVOICE')">
            <h4>Accepter et transformer en facture</h4>
          </div>
          <div class="item-ch">
            <h4>Accepter et transformer en commande</h4>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { converEstimateToInvoice } from "@/api/sales2";
import Loading from "@/components/Loanding/Full";
export default {
  name: "TRANSFORME-QUOTE",
  props: {
    number: String,
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: { Loading },
  data() {
    return {
      loadingFull: false,
    };
  },
  methods: {
    closeModal() {
      this.dialogVisible = false;
      this.$emit("closeDialog", false);
    },
    handleTransform(e) {
      if (e === "INVOICE") {
        this.closeModal();
        this.handleEstimateToInvoice();
      }
    },
    handleEstimateToInvoice() {
      this.loadingFull = true;
      converEstimateToInvoice(this.number)
        .then((res) => {
          setTimeout(() => {
            this.$router.push(`/sales/invoice/${res.data._id}`);
            this.loadingFull = false;
          }, 1000);
        })
        .catch(() => {
          this.loadingFull = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.ch {
  display: flex;
  flex-direction: column;
}
.ch .item-ch {
  width: 100%;
  height: 56px;
  border: 2px solid rgba(229, 231, 235, 1);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
}

.ch .item-ch:hover {
  color: $primary;
  border: 1px solid $primary;
}
</style>
